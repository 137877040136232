import { SortType } from "../Definition";
import NoteData from "./NoteData";

export type IComparer<T> = (a: T, b: T) => number;
/**
 * Compare 2 notes based on its title
 */
export function GetTitleComparer(type: SortType): IComparer<NoteData> {
    return (a: NoteData, b: NoteData) => {
        var titleA = Prepare2Compare(a.Title);
        var titleB = Prepare2Compare(b.Title);

        if (titleA < titleB) return type === SortType.Ascending ? 1 : -1;
        if (titleA > titleB) return type === SortType.Ascending ? -1 : 1;
        return 0;
    };
}
/**
 * Compare 2 notes based on create time
 */
export function GetCreateComparer(type: SortType): IComparer<NoteData> {
    return (a: NoteData, b: NoteData) => {
        if (a.CreateTime < b.CreateTime)
            return type === SortType.Ascending ? 1 : -1;
        if (a.CreateTime > b.CreateTime)
            return type === SortType.Ascending ? -1 : 1;
        return 0;
    };
}
/**
 * Compare 2 notes based on update time
 */
export function GetUpdateComparer(type: SortType): IComparer<NoteData> {
    return (a: NoteData, b: NoteData) => {
        if (a.UpdateTime < b.UpdateTime)
            return type === SortType.Ascending ? 1 : -1;
        if (a.UpdateTime > b.UpdateTime)
            return type === SortType.Ascending ? -1 : 1;
        return 0;
    };
}
/**
 * If last word in `str` is a number,
 * convert it to unicode character, otherwise, do nothing.
 */
function Prepare2Compare(str: string) {
    str = str.toLowerCase();
    str = RemoveUnicode2Compare(str);

    const words = str.split(" ");
    const lastWordInNumber = Number(words[words.length - 1]);
    if (isNaN(lastWordInNumber)) return str;

    // Convert number to unicode string (if any)
    return words
        .slice(0, words.length - 1)
        .join(" ")
        .concat(" ")
        .concat(String.fromCharCode(lastWordInNumber));
}
/**
 * Remove a unicode char with double of matched one.
 * When compare, it still match operators `<` and `>`.
 * ex: à -> 'aa' => 'aa' > 'a'
 */
function RemoveUnicode2Compare(str: string) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "aa");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "ee");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "ii");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "oo");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "uu");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "yy");
    str = str.replace(/đ/g, "dd");
    return str;
}
