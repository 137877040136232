import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
// DO NOT convert to import default
import * as firebaseui from "firebaseui";
import { UpdatableNote } from "../Data/NoteData";
import showAuthUi, {
    SignInFailureCallback,
    SignInSuccessCallback,
} from "./AuthUi";
import { Create, Delete, GetCollection, Update } from "./Firestore";

const config = JSON.parse(process.env.REACT_APP_FIREBASE_WEB_CONFIG || "{}");
const app = firebase.initializeApp(config);

// #region Authentication UI

const auth = firebase.auth();
const authUi = new firebaseui.auth.AuthUI(auth);

export type FirebaseUser = firebase.User | null;
export type FirebaseError = firebase.auth.Error | null;
export const signOut = auth.signOut.bind(auth);
export function onAuthStateChanged(
    nextOrObserver: firebase.Observer<any> | ((a: firebase.User | null) => any),
    error?: (a: firebase.auth.Error) => any,
    completed?: firebase.Unsubscribe
) {
    auth.onAuthStateChanged.call(auth, nextOrObserver, error, completed);
}
/**
 * @param success success callback
 * @param failure failure callback
 */
export function loginWithUi(
    success?: SignInSuccessCallback,
    failure?: SignInFailureCallback
) {
    showAuthUi(authUi, failure, success);
}

// #endregion Authentication UI

// #region Firestore

const firestore = getFirestore(app);

export const GetNotes = (seen?: boolean) => GetCollection(firestore, seen);
export const CreateNote = (note: UpdatableNote) => Create(firestore, note);
export const UpdateNote = (id: string, updates: UpdatableNote) =>
    Update(firestore, id, updates);
export const DeleteNote = (id: string) => Delete(firestore, id);

// #endregion Firestore
