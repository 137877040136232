import { useContext, createContext, useState, useEffect } from "react";
import { signOut, onAuthStateChanged, FirebaseError, FirebaseUser, loginWithUi } from '../../Firebase'
import { SignInFailureCallback, SignInSuccessCallback } from "../../Firebase/AuthUi";

// #region UserContext

export interface IUserContext {
    loading: boolean,
    user: FirebaseUser,
    error: FirebaseError
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export function useUserContext(): IUserContext {
    const context = useContext(UserContext);
    if (context == null)
        throw new Error('Must be used within UserProvider')

    return context;
}

// #endregion UserContext

// #region DispatchContext

export interface IUserDispatch {
    signOut: () => Promise<void>,
    /**
     * Return true to auto redirect, false to self handle auth result
     */
    showAuthUi: (
        success?: SignInSuccessCallback,
        failure?: SignInFailureCallback,
    ) => void
}

const UserDispatch = createContext<IUserDispatch>({} as IUserDispatch);

export function useUserDispatch(): IUserDispatch {
    const context = useContext(UserDispatch);
    if (context == null)
        throw new Error('Must be used within UserProvider')

    return context;
}

// #endregion DispatchContext

export interface UserProviderProps {
    children: any,
}

export default function UserProvider({ children }: UserProviderProps) {

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<FirebaseUser>(null);
    const [error, setError] = useState<FirebaseError>(null);

    useEffect(() => {
        onAuthStateChanged(
            (user) => {
                setUser(user);
                setLoading(false);
            },
            (error) => {
                setError(error);
                setLoading(false);
            }
        );
    }, []);

    const dispatcher: IUserDispatch = {
        signOut: () => {
            setUser(null);
            return signOut();
        },
        showAuthUi: (
            success?: SignInSuccessCallback,
            failure?: SignInFailureCallback,
        ) => {
            loginWithUi(success, failure);
        }
    }

    return (
        <UserContext.Provider value={{ loading, user, error }}>
            <UserDispatch.Provider value={dispatcher}>
                {children}
            </UserDispatch.Provider>
        </UserContext.Provider>
    )
}