export default class OgMeta {
    Url: string;
    Image: string;
    Title?: string | undefined;
    Description?: string | undefined;

    constructor(url: string, img: string, title: string, desc: string) {
        this.Url = url;
        this.Image = img;
        this.Title = title;
        this.Description = desc;
    }
}
