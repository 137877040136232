import {
	Filter, SortBy, Sorter,
	SortType, ViewMode
} from '../../Definition'
import { createContext, useContext } from 'react';

import Header from '../Header';
import Container from '../Container';
import DataProvider from './DataContext'
import Spinner from '../Common/Spinner';
import { useLocalStorage } from '../../hook/useStorage';
import MenuProvider from './MenuContext';
import ModalProvider from './ModalContext';
import UserProvider, { UserContext } from './UserContext';
import FirebaseAuthUi from '../Common/FirebaseAuthUi';
import NotificationProvider from './NotificationContext';
import Sidebar from '../Sidebar';

export type IAppContext = {
	filter: Filter,
	sorter: Sorter,
	viewMode: ViewMode
}

interface IAppDispatch {
	setViewMode: (mode: ViewMode) => void;
	setFilter: (filter: Filter) => void;
	setSorter: (by: SortBy | null, type: SortType | null) => void;
}

const DEFAULT_SORTER: Sorter = {
	By: SortBy.Alphabet,
	Order: SortType.Ascending
}

const AppContext = createContext<IAppContext>({} as IAppContext);
const DispatchContext = createContext<IAppDispatch | undefined>(undefined);

export default function App(): JSX.Element {
	const [viewMode, setViewMode] = useLocalStorage('view-mode', ViewMode.Grid);
	const [filter, setFilter] = useLocalStorage('filter', Filter.All);
	const [sorter, setSorter] = useLocalStorage('sorter', DEFAULT_SORTER);

	const dispatcher: IAppDispatch = {
		setViewMode, setFilter,
		setSorter: (by = null, type = null) => {
			var old = { ...sorter || DEFAULT_SORTER };
			if (by != null)
				old.By = by;
			if (type != null)
				old.Order = type;
			setSorter(old);
		}
	}

	const toolbar: IAppContext = {
		filter: filter || Filter.All,
		sorter: sorter || DEFAULT_SORTER,
		viewMode: viewMode || ViewMode.Grid
	}

	return (
		<div className='fluid-container mx-3'>
			<NotificationProvider>
				<UserProvider>
					<UserContext.Consumer>
						{({ loading, user }) => {
							if (loading)
								return <Spinner
									className="text-primary vh-100"
									hCenter={true} vCenter={true} />

							if (user == null)
								return <FirebaseAuthUi />

							return (
								<>
									<Sidebar />
									<AppContext.Provider value={toolbar}>
										<DispatchContext.Provider value={dispatcher}>
											<DataProvider>
												<ModalProvider>
													<Header />
													<MenuProvider>
														<Container />
													</MenuProvider>
												</ModalProvider>
											</DataProvider>
										</DispatchContext.Provider>
									</AppContext.Provider>
								</>
							)
						}}
					</UserContext.Consumer>
				</UserProvider>
			</NotificationProvider>
		</div>
	);
}

export function useAppContext(): IAppContext {
	const context = useContext(AppContext);
	if (context == null)
		throw new Error('Must be used within App')

	return context;
}

export function useAppDispatch(): IAppDispatch {
	const context = useContext(DispatchContext);
	if (context == null)
		throw new Error('Must be used within App')

	return context;
}
