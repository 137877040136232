import React, { useRef } from 'react'
import { SortBy, SortType } from '../../Definition';
import HeaderSorterItem from './HeaderSorterItem';
import { Dropdown } from 'bootstrap'
import { useAppContext, useAppDispatch } from '../Context/AppContext';

const SORTERS = [
    {
        class: 'ic-alphabet-asc',
        name: "Alphabet asc",
        value: [SortBy.Alphabet, ".", SortType.Ascending].join(""),
    },
    {
        class: 'ic-alphabet-desc',
        name: "Alphabet desc",
        value: [SortBy.Alphabet, ".", SortType.Descending].join(""),
    },
    {
        class: 'dropdown-divider',
        value: 'alphabet-divider'
    },
    {
        class: 'ic-date-asc',
        name: "CreateTime asc",
        value: [SortBy.CreateTime, ".", SortType.Ascending].join(""),
    },
    {
        class: 'ic-date-desc',
        name: "CreateTime desc",
        value: [SortBy.CreateTime, ".", SortType.Descending].join(""),
    },
    {
        class: 'dropdown-divider',
        value: 'create-divider'
    },
    {
        class: 'ic-date-asc',
        name: "UpdateTime asc",
        value: [SortBy.UpdateTime, ".", SortType.Ascending].join(""),
    },
    {
        class: 'ic-date-desc',
        name: "UpdateTime desc",
        value: [SortBy.UpdateTime, ".", SortType.Descending].join(""),
    },
];

export interface HeaderSorterProps { }

export default function HeaderSorter(props: HeaderSorterProps) {
    const { sorter } = useAppContext();
    const { setSorter } = useAppDispatch();

    const dropdownToggle = useRef<HTMLButtonElement>(null);
    if (dropdownToggle.current) {
        Dropdown.getOrCreateInstance(dropdownToggle.current);
    }

    function onClick(e: React.MouseEvent<HTMLDivElement>) {
        const target = e.target as HTMLDivElement;
        let item = target.parentElement;
        while (item?.hasAttribute('data-value') === false)
            item = item.parentElement;

        if (item == null)
            return;
        if (item.classList.contains('dropdown-item') === false)
            return;

        const values = item.getAttribute('data-value')?.split('.');
        if (Array.isArray(values))
            setSorter(values[0] as SortBy, values[1] as SortType);
    }

    const activeSorter = sorter.By + '.' + sorter.Order;
    return (
        <div className="dropdown input-group-append">
            <button ref={dropdownToggle} className="btn border-secondary btn-sort" type="button" data-bs-toggle="dropdown"></button>
            <div onClick={onClick} className="dropdown-menu dropdown-menu-right sort" data-role="note-sort">
                {SORTERS.map(s => (
                    <HeaderSorterItem key={s.value}
                        className={s.class} active={s.value === activeSorter}
                        name={s.name} value={s.value} />
                ))}
            </div>
        </div>
    )
}
