import { useCallback, useRef } from "react";
import useConfigurableOpenGraph from "../../../hook/useConfigurableOpenGraph";

export default function ConfigOg() {
    const { baseApiUrl, setBaseUrl } = useConfigurableOpenGraph();
    const ogInput = useRef<HTMLInputElement>(null);

    const saveSuccess = useCallback((input: HTMLInputElement) => {
        if (input) {
            input.classList.add('is-valid');
            setTimeout(() => input.classList.remove('is-valid'), 2000);
        }
    }, []);

    function saveConfig() {
        const ogValue = ogInput.current?.value;
        if (ogValue !== baseApiUrl) {
            setBaseUrl(ogValue);
            if (ogInput.current)
                saveSuccess(ogInput.current);
        }
    }

    return (
        <div>
            <div className="input-group">
                <input ref={ogInput} defaultValue={baseApiUrl}
                    type="text" className="form-control" placeholder="Base url, ex: http://localhost" />
                <div className="input-group-append">
                    <button onClick={() => saveConfig()} className="btn btn-outline-success" type="button">Save</button>
                </div>
            </div>
        </div >
    )
}