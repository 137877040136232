enum MenuAction {
    Unknown = "",
    Edit = "edit",
    Delete = "delete",
    Update = "update",
}

export default MenuAction;

export function parse(value: string | null | undefined): MenuAction {
    if (value == null || value.length <= 1) return MenuAction.Unknown;

    switch (value.trim().toLowerCase()) {
        case "edit":
            return MenuAction.Edit;
        case "update":
            return MenuAction.Update;
        case "delete":
            return MenuAction.Delete;
        default:
            return MenuAction.Unknown;
    }
}
