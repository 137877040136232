export enum ViewMode {
    Grid = "grid-view",
    List = "list-view",
}

export enum Filter {
    All = "all",
    New = "new",
    Seen = "seen",
}

export enum SortBy {
    Alphabet = "alphabet",
    CreateTime = "create-time",
    UpdateTime = "update-time",
}

export enum SortType {
    Ascending = "asc",
    Descending = "desc",
}

export interface Sorter {
    By: SortBy;
    Order: SortType;
}

export type Predicate<T> = (item: T) => boolean;
