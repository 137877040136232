
export interface UndoToastProps {
    content: string | JSX.Element,
    undo?: () => void,
    closeToast?: () => void
}

export default function UndoToast({ content, undo, closeToast }: UndoToastProps) {
    const handleClick = () => {
        if (typeof undo === 'function')
            undo.call(undo);
        if (typeof closeToast === 'function')
            closeToast();
    };

    return (
        <div role="alert" className="Toastify__toast-body">
            <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
                <svg viewBox="0 0 24 24" width="100%" height="100%" fill="var(--toastify-icon-color-info)"><path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path></svg>
            </div>
            <div className="d-flex justify-content-between">
                <div>{content}</div>
                <div onClick={handleClick}><b className="text-info">undo</b></div>
            </div>
        </div>
    );
}