import React from "react"

export const CLONED_ATTR = 'cloned';
export const DUPLICATABLE_ATTR = 'duplicatable';

export interface ContentItemProps {
    content?: string,
    onFocus: React.FocusEventHandler<HTMLElement>
}

export default function ContentItem({ content, onFocus }: ContentItemProps) {
    return (
        <div className="padding-sm"
            data-role="content"
            contentEditable="true" placeholder="Content"
            suppressContentEditableWarning={true}
            onFocus={onFocus} {...{ [DUPLICATABLE_ATTR]: 'true' }}>
            {content}
        </div>
    )
}