export interface EmptyProps {
    /**
     * true -> { width = 100%; text-align = center; }
     */
    center?: boolean,
    className?: string
}
/**
 * Element with text "Empty"
 */
export default function Empty(props: EmptyProps) {
    const { center = false, className = "" } = props;
    const centerClass = center ? 'w-100 text-center' : '';

    return (
        <div className={[className, centerClass].join(' ')}>
            <span>Empty</span>
        </div>
    )
}