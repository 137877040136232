import React, { useEffect, useMemo, useState } from "react"
import { useDataContext, useDataDispatch } from "../Context/DataContext";
import Autosuggest, { RenderInputComponentProps } from 'react-autosuggest';

export interface SearchBarProps { }
export type SuggestionItem = { text: string, value: string }

export default function SearchBar(props: SearchBarProps) {
    const { search, backSearch } = useDataDispatch();
    const { searchTerm, GetAllValues } = useDataContext();

    const [shouldUseSuggestion, shouldUseSuggestionWhen] = useState(false);
    const [searchBoxValue, setSearchBoxValue] = useState<string | null | undefined>(undefined);
    const [suggestions, setSuggestions] = useState<SuggestionItem[]>([]);

    const searchDataSource = useMemo(() => {
        let notes = GetAllValues();
        if (Array.isArray(notes) && notes.length > 0) {
            return notes.map(n => ({
                text: n.Title,
                value: n.KeyWords.join(' ')
            } as SuggestionItem));
        }

        return [];
    }, [GetAllValues])

    useEffect(() => setSearchBoxValue(searchTerm), [searchTerm]);

    function searchBoxKeydown(e: React.KeyboardEvent) {
        if (shouldUseSuggestion)
            return;
        if (e.key === 'Enter') {
            search((e.target as HTMLInputElement).value);
            return;
        }
    }

    function renderInputComponent(props: RenderInputComponentProps) {
        return (
            <div className="input-group" data-role="search-bar">
                <div className="input-group-prepend">
                    <span className="input-group-text search-box"></span>
                </div>
                <input {...props} onKeyDownCapture={searchBoxKeydown}
                    // Why onKeyDown not working here??
                    type="text" data-role="search-box"
                    className="form-control rounded-right ui-autocomplete-input"
                    placeholder="Note title" autoComplete="off" />
                <span onClick={() => backSearch()}
                    className={
                        'sm-icon ic-back align-self-center'
                        + (searchTerm ? '' : ' hidden')
                    }
                    data-role="undo-search"></span>
            </div >
        )
    }

    return (
        <Autosuggest
            suggestions={suggestions}
            renderInputComponent={renderInputComponent}
            renderSuggestion={s => <div>{s.text}</div>}
            getSuggestionValue={({ text: name }) => name}
            onSuggestionsClearRequested={() => setSuggestions([])}
            onSuggestionHighlighted={({ suggestion }) => shouldUseSuggestionWhen(suggestion != null)}
            onSuggestionsFetchRequested={({ value }) => {
                const _value = value.trim().toLowerCase();
                if (_value.length === 0)
                    setSuggestions([]);
                else {
                    let s = searchDataSource.filter(s => s.value.toLowerCase().slice(0, _value.length) === _value);
                    setSuggestions(s);
                }
            }}
            onSuggestionSelected={(_, { suggestion }) => {
                setSearchBoxValue(suggestion.text);
                if (suggestion.value && suggestion.value.length)
                    search(suggestion.text);
            }}
            inputProps={{
                value: searchBoxValue || '',
                onChange: ({ target }) => setSearchBoxValue((target as HTMLInputElement).value)
            }}
        />
        // <div className="input-group" data-role="search-bar">
        //     <div className="input-group-prepend">
        //         <span className="input-group-text search-box"></span>
        //     </div>
        //     <input onKeyDown={searchBoxKeydown}
        //         // “controlled components”
        //         value={value || ""} onChange={e => setValue(e.target.value)}

        //         type="text" data-role="search-box"
        //         className="form-control rounded-right ui-autocomplete-input"
        //         placeholder="Note title" autoComplete="off" />
        //     <span onClick={() => backSearch()}
        //         className={
        //             'sm-icon ic-back align-self-center'
        //             + (searchTerm ? '' : ' hidden')
        //         }
        //         data-role="undo-search"></span>

        // </div >
    )
}