import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import NoteData from "../../Data/NoteData";
import MenuAction, { parse } from "./MenuAction";

import './style.css'

export type ClickEventArg = {
    action: MenuAction
    data: string | null | undefined
}

export interface MenuProps {
    /**
     * True -> show menu
     */
    show: boolean,
    /**
     * The element which menu should be shown next to
     */
    refElem: HTMLElement | null,
    note: NoteData | null,
    onHidden?: () => void,
    onClick?: (arg: ClickEventArg) => void;
}

export default function Menu(props: MenuProps) {

    const { show, refElem, note, onHidden, onClick } = props;
    const [menu, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(refElem, menu);

    useEffect(() => {
        if (refElem == null)
            return;

        if (show) {
            menu?.classList.add('show');
        }
        else {
            menu?.classList.remove('show');

            if (typeof onHidden === 'function')
                onHidden.call(onHidden);
        }

    }, [show, refElem])

    function onItemClick(e: React.MouseEvent<HTMLDivElement>) {
        const target = e.target as HTMLElement;
        const action = target.getAttribute('data-action');

        if (typeof onClick === 'function') {
            onClick.call(onclick, {
                action: parse(action),
                data: target.getAttribute('data-arg')
            });
        }
    }
    return (
        <div onClick={onItemClick} ref={setPopperElement} className="option-menu" data-role="option-menu"
            style={styles.popper} {...attributes.popper}>
            <div className="item-wrapper">
                <a className="menu-item" data-action={MenuAction.Edit}>Edit</a>
                <a className="menu-item" data-action={MenuAction.Delete}>Delete</a>
                <a className="menu-item" role="group-title">
                    Colors
                    <ul className="item-grid">
                        <li className={'color-item default' + (note?.Color === 'default' ? ' active' : '')} data-arg="default" data-action={MenuAction.Update} />
                        <li className={'color-item danger' + (note?.Color === 'danger' ? ' active' : '')} data-arg="danger" data-action={MenuAction.Update} />
                        <li className={'color-item orange' + (note?.Color === 'orange' ? ' active' : '')} data-arg="orange" data-action={MenuAction.Update} />
                        <li className={'color-item yellow' + (note?.Color === 'yellow' ? ' active' : '')} data-arg="yellow" data-action={MenuAction.Update} />
                        <li className={'color-item green' + (note?.Color === 'green' ? ' active' : '')} data-arg="green" data-action={MenuAction.Update} />
                        <li className={'color-item cyan' + (note?.Color === 'cyan' ? ' active' : '')} data-arg="cyan" data-action={MenuAction.Update} />
                        <li className={'color-item blue' + (note?.Color === 'blue' ? ' active' : '')} data-arg="blue" data-action={MenuAction.Update} />
                        <li className={'color-item purple' + (note?.Color === 'purple' ? ' active' : '')} data-arg="purple" data-action={MenuAction.Update} />
                        <li className={'color-item pink' + (note?.Color === 'pink' ? ' active' : '')} data-arg="pink" data-action={MenuAction.Update} />
                    </ul>
                </a>
            </div>
        </div>
    )
}