import { Filter, Predicate, SortBy, Sorter } from "../Definition";
import NoteData, { UpdatableNote } from "./NoteData";
import {
    GetCreateComparer,
    GetTitleComparer,
    GetUpdateComparer,
    IComparer,
} from "./Comparer";
import { RemoveUnicode } from "../Util/StringUtil";

export default class DataCollection {
    private notes: NoteData[];
    private keyword?: string;
    private comparer: IComparer<NoteData> | undefined;
    private predicate: Predicate<NoteData> | undefined;

    constructor(source: NoteData[] | undefined = undefined) {
        this.notes = (source || []).map(NoteData.FromJson);
        this.keyword = undefined;
    }
    /**
     * All items from this collection
     */
    get AllItems() {
        return this.notes;
    }
    /**
     * Get items filtered from all internal items.
     */
    get FilteredItems() {
        let output = this.notes;
        if (this.keyword != null && this.keyword.length > 0) {
            const segments = this.keyword.toLowerCase().split(" ").reverse();

            let word: string | undefined;
            while ((word = segments.pop()) != null) {
                output = output.filter((n) => {
                    if (n.KeyWords.indexOf(word as string) !== -1) return true;
                    return n.KeyWords[0]?.startsWith(word as string);
                });
            }
        }
        //
        else if (this.predicate) output = output.filter(this.predicate);
        if (this.comparer) output = output.sort(this.comparer);

        return output;
    }
    /**
     * @returns Added note with generated Id
     */
    Add(note: NoteData): NoteData {
        if (note.Id == null || note.Id.length == 0) note.GenerateId();
        this.notes.unshift(note);
        return note;
    }
    /**
     * Update note by specified @param id
     * @returns Updated note
     */
    Update(id: string, updates: UpdatableNote): NoteData | undefined {
        if (typeof id !== "string" || id.length <= 1) return undefined;
        if (updates == null) return undefined;

        let targetIndex = -1;
        let target = this.notes.find((n, index) => {
            targetIndex = index;
            return n.Id === id;
        });

        if (target == null) return undefined;

        let key: keyof UpdatableNote;
        for (key in updates) {
            const value = updates[key];
            if (value == null) continue;

            switch (key) {
                case "Color":
                    target.Color = value as string;
                    break;

                case "Content":
                    target.Content = (value as string[]).filter(
                        (c) => c.length > 0
                    );
                    break;

                case "Link":
                    target.Link = value as string;
                    break;

                case "Seen":
                    target.Seen = value as boolean;
                    break;

                case "Title":
                    target.Title = value as string;
                    break;

                case "UpdateTime":
                    target.UpdateTime = value as number;
                    break;
            }
        }

        this.notes[targetIndex] = target;
        return target;
    }
    /**
     * Remove note by specified @param id from the collection
     * @returns Removed note, or undefined if not found
     */
    Remove(id: string): NoteData | undefined {
        const index = this.notes.findIndex((n) => n.Id === id);
        if (index < 0) return undefined;

        const removed = this.notes.splice(index, 1);
        return removed[0];
    }

    Sort(sorter: Sorter): DataCollection {
        switch (sorter.By) {
            case SortBy.Alphabet:
                this.comparer = GetTitleComparer(sorter.Order);
                break;

            case SortBy.CreateTime:
                this.comparer = GetCreateComparer(sorter.Order);
                break;
            case SortBy.UpdateTime:
                this.comparer = GetUpdateComparer(sorter.Order);
                break;
        }
        return this;
    }

    Filter(filter: Filter): DataCollection {
        switch (filter) {
            case Filter.New:
                this.predicate = (note: NoteData) => note.Seen === false;
                break;

            case Filter.Seen:
                this.predicate = (note: NoteData) => note.Seen === true;
                break;

            default:
                this.predicate = (note: NoteData) => true;
                break;
        }

        return this;
    }

    Search(keyword: string): DataCollection {
        this.keyword = RemoveUnicode(keyword);
        return this;
    }
}
