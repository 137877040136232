import { createContext, useContext, useState } from 'react'
import { createPortal } from 'react-dom';
import NoteData, { UpdatableNote } from '../../Data/NoteData';
import NoteModal from '../Modal/Note/NoteModal';
import { useDataDispatch } from './DataContext';
import { CreateNote, UpdateNote } from '../../Firebase';
import { toast } from 'react-toastify';

// #region ModalContext

interface IModalContext {
}

const ModalContext = createContext<IModalContext>({} as IModalContext);

export function useModalContext(): IModalContext {
    const context = useContext(ModalContext);
    if (context == null)
        throw new Error('Must be used within ModalProvider')

    return context;
}

// #endregion ModalContext

// #region DispatchContext

interface IModalDispatch {
    showModal: (note: NoteData | null) => void;
}

const DispatchContext = createContext<IModalDispatch>({} as IModalDispatch);

export function useModalDispatch(): IModalDispatch {
    const context = useContext(DispatchContext);
    if (context == null)
        throw new Error('Must be used within ModalProvider')

    return context;
}

// #endregion DispatchContext

export interface ModalProviderProps {
    children?: any
}

export default function ModalProvider({ children }: ModalProviderProps) {
    const { localCreate, localUpdate } = useDataDispatch();
    const [show, setShow] = useState(false);
    const [note, setNote] = useState<NoteData | null>(null);
    const isAddContext = note == null || note.IsEmpty;

    function createOrUpdate(updates: UpdatableNote) {
        if (updates == null)
            return;

        if (isAddContext) {
            toast.promise(CreateNote(updates), {
                pending: {
                    render: () => {
                        setShow(false);
                        setNote(null);
                        return 'Creating ...';
                    }
                },
                success: {
                    render: ({ data }) => {
                        data && localCreate(data);
                        return <span>Created item <b>{data?.Title}</b></span>
                    }
                },
                error: {
                    render: ({ data }) => {
                        console.error(data);
                        return 'Unknown error';
                    }
                }
            })
        }
        else {
            toast.promise(UpdateNote(note.Id, updates), {
                pending: {
                    render: () => {
                        setShow(false);
                        setNote(null);
                        return 'Updating ...';
                    }
                },
                error: {
                    render: ({ data }) => {
                        console.info(data);
                        return 'unknown error';
                    }
                },
                success: {
                    render: ({ data }) => {
                        updates = UpdatableNote.From(data);
                        localUpdate(note.Id, updates);
                        return <span>Updated item <b>{data?.Title}</b></span>
                    }
                }
            })
        }
    }

    const context: IModalContext = {}

    const dispatcher: IModalDispatch = {
        showModal: (note: NoteData | null) => {
            // set Empty to refresh data when model is shown
            setNote(note || NoteData.Empty);
            setShow(true);
        }
    }

    return (<>
        <ModalContext.Provider value={context}>
            <DispatchContext.Provider value={dispatcher}>
                {children}
            </DispatchContext.Provider>
        </ModalContext.Provider>
        {
            createPortal(
                <>
                    <NoteModal show={show} note={note}
                        onHidden={() => { setShow(false); setNote(null) }}
                        title={note == null ? 'Create note' : 'Update note'}
                        onOk={createOrUpdate} />
                </>,
                document.getElementById('root') as Element
            )
        }
    </>);
}