import { useState } from "react";
import NoteData, { UpdatableNote, ValidateResult } from "../../../Data/NoteData";
import BaseModal, { ModalProps } from "../BaseModal";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";

export interface NoteModalProps extends ModalProps {
    /**
     * Popup title
     */
    title: string,
    note?: NoteData | null,
    onOk?: (updates: UpdatableNote) => void,
}

export default function NoteModal(props: NoteModalProps) {
    const {
        title, note, onOk = () => { },
    } = props;

    const [modelState, setState] = useState<ValidateResult | null>(null);

    function onSave(modal: HTMLElement) {
        if (typeof onOk !== 'function')
            return;

        const title = modal.querySelector('[data-role="title"]');
        const link = modal.querySelector('[data-role="link"]');
        const content = modal.querySelectorAll('[data-role="content"]');

        const updates = new UpdatableNote();
        updates.Title = title?.textContent || '';
        updates.Link = link?.textContent || '';
        updates.Content = Array.from<Element>(content)
            .filter(c => c.textContent != null && c.textContent.length > 0)
            .map(c => c.textContent as string);

        const validateResult = updates.Validate();
        if (validateResult.IsSuccess) {
            setState(null);
            onOk.call(onOk, updates);
        }
        else {
            setState(validateResult);
        }
    }

    return <BaseModal {...props}
        header={< Header title={title} />}
        body={< Body modelState={modelState} note={note} />}
        footer={< Footer onOk={onSave} />} />
}