import './style.css'

import React, { useEffect, useRef, useState } from "react"
import NoteData, { UpdatableNote } from "../../Data/NoteData"
import { useDataDispatch } from '../Context/DataContext';
import useTargetingUpdate, { UpdateTarget } from './useTargetingUpdate';
import { Collapse } from 'bootstrap'
import PreviewCard, { CardData } from './PreviewCard';
import NoteTitle from './NoteTitle';
import fetchOpenGraph from '../../Util/OpenGraph/FetchOpenGraph';
import { useMenuDispatch } from '../Context/MenuContext';
import { UpdateNote } from '../../Firebase';
import useConfigurableOpenGraph from '../../hook/useConfigurableOpenGraph';

export interface ItemProps {
    data: NoteData
}

export default function Item({ data }: ItemProps) {
    const { showMenu } = useMenuDispatch();
    const { localUpdate } = useDataDispatch();
    const { baseApiUrl, createEndpoint } = useConfigurableOpenGraph();
    const { target, updateComplete, updateCheckBox } = useTargetingUpdate();
    const [expanded, setExpand] = useState<boolean | undefined>(undefined);
    const [ogData, setOg] = useState<CardData | null | undefined>(undefined);
    const collapserRef = useRef<HTMLDivElement>(null);
    const shouldFetchPreviewCard = baseApiUrl && data.Link && data.Link.startsWith('http');

    // Collapse events
    useEffect(() => {
        if (collapserRef.current == null)
            return;

        // Before expand
        collapserRef.current.addEventListener('show.bs.collapse',
            () => {
                if (expanded === undefined) {
                    if (shouldFetchPreviewCard) {
                        fetchOpenGraph(createEndpoint(data.Link))
                            .then(CardData.FromOgMeta)
                            .then(setOg)
                            .catch(err => { setOg(null); console.error(err) })
                    }
                }
            }, { once: true })
        // After expand
        collapserRef.current.addEventListener('shown.bs.collapse',
            () => setExpand(true))
        // Before collapse
        collapserRef.current.addEventListener('hide.bs.collapse',
            e => { })
        // After collapse
        collapserRef.current.addEventListener('hidden.bs.collapse',
            () => setExpand(false))

    }, [])

    useEffect(() => {
        setExpand(false);
    }, [data.Link])

    function onItemClick(e: React.MouseEvent<HTMLDivElement>) {
        const target = e.target as HTMLElement;

        switch (target.getAttribute('data-role')) {
            case 'seen':
                const seen = UpdatableNote.Seen(!data.Seen);
                updateCheckBox()
                localUpdate(data.Id, seen, false)
                    .then(() => UpdateNote(data.Id, seen))
                    .then(updateComplete);
                break;

            case 'option':
                showMenu(e.target as HTMLElement, data);
                break;

            default:
                if (data.ShouldExpand) {
                    const outOfCard = target.closest('.note-preview') == null;
                    if (collapserRef.current && outOfCard) {
                        Collapse.getOrCreateInstance(collapserRef.current).toggle();
                    }
                }
                break;
        }
    }

    const checkboxClasses = [
        'checkbox',
        data.Seen ? 'checked' : '',
        target === UpdateTarget.CheckBox ? 'loading' : ''
    ];

    return (
        <div className="list-group">
            <div onClick={onItemClick} className="list-group-item note-item"
                style={{ '--note-color': `var(--${data.Color})` } as React.CSSProperties}>
                <div className="note">
                    <div data-role="seen" className={checkboxClasses.join(' ')}></div>
                    <NoteTitle title={data.Title} />
                    <div className="option" data-role="option" title="option">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                        </svg>
                    </div>
                </div>
                <div ref={collapserRef} className={'note-preview collapse' + (expanded ? ' show' : '')}>
                    <div className="list-group note-group">
                        {Array.isArray(data?.Content) && data.Content.map((c, i) => <div key={i} className="list-group-item">{c}</div>)}
                        {
                            (ogData != null || !data.Link) ? null : (
                                <div className="list-group-item link-item">
                                    <a className='d-block' href={data.Link} target="_blank" rel="noreferrer">{data.DisplayLink}</a>
                                </div>
                            )
                        }
                    </div>
                    {(expanded !== undefined && shouldFetchPreviewCard) && <PreviewCard data={ogData} />}
                </div>
            </div>
        </div>
    )
}