import './style.css'

import Masonry from "react-masonry-css";
import { ViewMode } from "../../Definition"
import { useAppContext } from "../Context/AppContext";
import { useDataContext } from "../Context/DataContext";
import Item from "../Item";
import Empty from '../Common/Empty';
import Spinner from '../Common/Spinner';

const CONTAINER_BREAK_POINT = {
    576: 1, // One col on small screen
    default: 3,
}

export interface ContainerProps {
}

export default function Container(props: ContainerProps): JSX.Element {
    const { viewMode } = useAppContext();
    const dataContext = useDataContext();

    const breakpoint = viewMode === ViewMode.Grid
        ? CONTAINER_BREAK_POINT
        : 1;    // Always 1 col on small screen

    if (dataContext.loading) {
        return <Spinner hCenter={true} className="text-primary mt-3" />
    }

    return (
        <div className="row body justify-content-center mt-3" data-role="note-container">
            <div className="col">
                {
                    Array.isArray(dataContext.FilteredValue) && dataContext.FilteredValue.length > 0
                        ? (
                            <Masonry breakpointCols={breakpoint}
                                className="masonry-grid" columnClassName="masonry-col">
                                {dataContext.FilteredValue.map(i => <Item key={i.Id} data={i} />)}
                            </Masonry>
                        )
                        : (
                            <Empty center={true} />
                        )
                }
            </div>
        </div>
    )
}