import { useUserDispatch } from "../../Context/UserContext";

export default function Account() {
    const { signOut } = useUserDispatch();

    return (
        <div className="d-flex">
            <button onClick={() => signOut()} data-bs-dismiss="offcanvas" className="btn btn-outline-success">Signout</button>
        </div>
    )
}