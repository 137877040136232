
export interface ModalHeaderProps {
    title: string
}

export default function Header({ title }: ModalHeaderProps) {
    return (<>
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </>)
}