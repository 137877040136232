import React from "react";
import NoteData, { ValidateResult } from "../../../Data/NoteData"
import ContentItem, { CLONED_ATTR, DUPLICATABLE_ATTR } from "./ContentItem";

export interface BodyProps {
    note?: NoteData | null,
    modelState?: ValidateResult | null
}

export default function Body({ note, modelState }: BodyProps) {

    function selectAll(target: HTMLElement) {
        window.getSelection()?.selectAllChildren(target)
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLElement>) {
        const target = e.target as HTMLElement;

        if (e.key === 'Enter') {
            e.preventDefault();

            if (target.getAttribute('data-role') === 'link') {
                console.log('TODO: submit');
                return;
            }

            if (target.hasAttribute(DUPLICATABLE_ATTR)) {
                // Add new input below [DUPLICATABLE_ATTR]
                const newElem = target.cloneNode() as HTMLElement;
                newElem.setAttribute(CLONED_ATTR, 'true');
                newElem.addEventListener('focus', e => selectAll(e.target as HTMLElement));

                target.after(newElem);
                newElem.focus();

                return;
            }
        }

        // Ctrl + del -> Remove [CLONED_ATTR] focused input
        if (e.key === 'Delete' && e.ctrlKey) {
            if (target.hasAttribute(CLONED_ATTR)) {
                (target.nextElementSibling as HTMLElement)?.focus();
                target.remove();

                return;
            }
        }
    }

    const index = modelState?.States.indexOf('Title');
    const titleValid = index == null || index < 0;

    const content = note?.Content.map((c, i) =>
        <ContentItem key={i} content={c} onFocus={({ target }) => selectAll(target)} />)

    if (content?.length === 0) {
        // When hidden, context set note to null,
        // -> remove content element -> refresh data when reopen
        content.push(<ContentItem key={-1} onFocus={({ target }) => selectAll(target)} />)
    }

    return (
        <div onKeyDown={onKeyDown} className="list-group note-group">
            <div className="list-group-item input-group position-relative p-0">
                <div className={'padding-sm' + (titleValid ? '' : ' is-invalid')}
                    suppressContentEditableWarning={true}
                    onFocus={({ target }) => selectAll(target)} contentEditable="true" placeholder="Title" data-role="title">
                    {note?.Title}
                </div>
                <label className="m-0 p-0 invalid-feedback"></label>
            </div>
            <div className="list-group-item note-content input-group position-relative border-bottom-0 p-0">
                {content}
            </div>
            <div className="list-group-item padding-sm"
                data-role="link"
                suppressContentEditableWarning={true}
                onFocus={({ target }) => selectAll(target)} contentEditable="true" placeholder="Link">
                {note?.Link}
            </div>
        </div>
    )
}