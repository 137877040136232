import {
    Firestore,
    collection,
    getDocs,
    FirestoreError,
    FirestoreDataConverter,
    doc,
    setDoc,
    updateDoc,
    runTransaction,
    query,
    where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import NoteData, { UpdatableNote } from "../Data/NoteData";
import { GenerateKeyWords } from "../Util/StringUtil";

const MAIN_COLLECTION =
    process.env.REACT_APP_FIRESTORE_COLLECTION_NAME || "dev";
const BACKUP_COLLECTION =
    process.env.REACT_APP_FIRESTORE_REMOVED_COLLECTION_NAME || "removed";

const CR_CONVERTER: FirestoreDataConverter<NoteData> = {
    toFirestore: (note) => {
        delete note.Id;
        note.UpdateTime = new Date().getTime();
        return { ...note };
    },
    fromFirestore: (snapshot, options) => {
        const output = NoteData.FromJson(snapshot.data(options));
        output.Id = snapshot.id;
        return output;
    },
};
const U_CONVERTER: FirestoreDataConverter<UpdatableNote> = {
    fromFirestore: (_) => ({} as UpdatableNote), // Not use
    toFirestore: (updates: UpdatableNote) => {
        let shouldGenKeyword = false;
        let key: keyof UpdatableNote;
        for (key in updates) {
            if (updates[key] == null) {
                delete updates[key];
                continue;
            }
            shouldGenKeyword = key == "Title" || key == "Content";
        }
        if (shouldGenKeyword)
            updates.KeyWords = GenerateKeyWords(updates.Title, updates.Content);
        if (Object.keys(updates).length > 0)
            updates.UpdateTime = new Date().getTime();

        return { ...updates };
    },
};

export async function GetCollection(
    db: Firestore,
    seen?: boolean
): Promise<NoteData[]> {
    try {
        const col = collection(db, MAIN_COLLECTION).withConverter(CR_CONVERTER);
        const snapshot =
            seen == null
                ? await getDocs(col)
                : await getDocs(query(col, where("Seen", "==", seen)));
        return snapshot.docs.filter((d) => d.exists()).map((d) => d.data());
    } catch (err) {
        console.error(err);
        toast.error((err as FirestoreError).toString(), { autoClose: 3000 });
        return [];
    }
}

export async function Create(
    db: Firestore,
    data: UpdatableNote
): Promise<NoteData> {
    const note = NoteData.FromProps(data);
    if (note == null) {
        const msg = "";

        toast.error(msg, { autoClose: 3000 });
        throw new Error(msg);
    }

    note.CreateTime = new Date().getTime();
    note.GenerateKeyword();

    const newRef = doc(collection(db, MAIN_COLLECTION)).withConverter(
        CR_CONVERTER
    );

    try {
        await setDoc(newRef, note);

        data.Id = note.Id = newRef.id;
        return note;
    } catch (err) {
        console.error(err);
        toast.error((err as FirestoreError).toString(), { autoClose: 3000 });
        return new NoteData();
    }
}

export async function Update(
    db: Firestore,
    id: string,
    updates: UpdatableNote
): Promise<NoteData> {
    let shouldGenKeyword = false;
    let key: keyof UpdatableNote;
    for (key in updates) {
        if (updates[key] == null) {
            delete updates[key];
            continue;
        }
        shouldGenKeyword = key == "Title" || key == "Content";
    }
    if (shouldGenKeyword)
        updates.KeyWords = GenerateKeyWords(updates.Title, updates.Content);
    if (Object.keys(updates).length > 0)
        updates.UpdateTime = new Date().getTime();

    const target = doc(db, MAIN_COLLECTION, id).withConverter(U_CONVERTER);
    await updateDoc(target, { ...updates });
    return NoteData.FromProps(updates) as NoteData;
}

export async function Delete(
    db: Firestore,
    id: string
): Promise<NoteData | undefined> {
    let output: NoteData | undefined = undefined;

    await runTransaction(db, async (trans) => {
        const delRef = doc(db, MAIN_COLLECTION, id).withConverter(CR_CONVERTER);
        const snapshot = await trans.get(delRef);
        if (snapshot.exists()) {
            output = snapshot.data();
            trans.set(doc(collection(db, BACKUP_COLLECTION)), {
                Color: output.Color,
                Title: output.Title,
                Seen: output.Seen,
                Link: output.Link,
                Content: output.Content,
            });

            trans.delete(delRef);
        }
    });

    return output;
}
