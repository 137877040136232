import { useCallback } from "react";
import { useLocalStorage } from "./useStorage";

const STORAGE_KEY = "ogapi-base-url";
const DEFAULT_BASE_URL = "";

export default function useConfigurableOpenGraph() {
    const [baseApiUrl, setBaseUrl] = useLocalStorage<string>(
        STORAGE_KEY,
        DEFAULT_BASE_URL
    );

    const createEndpoint = useCallback(
        (target: string) => {
            if (baseApiUrl != null && baseApiUrl.startsWith("http")) {
                const req = new URL(baseApiUrl as string);
                req.searchParams.append("url", encodeURIComponent(target));
                return req.href;
            }

            return "";
        },
        [baseApiUrl]
    );

    return {
        baseApiUrl,
        setBaseUrl,
        createEndpoint,
    };
}
