import firebase from "firebase/compat/app";

export type SignInSuccessCallback = (
    result: any,
    redirectUrl?: string
) => boolean;

export type SignInFailureCallback = (
    error: firebaseui.auth.AuthUIError
) => Promise<void> | void;

/**
 * Style sheet for firebase autn UI
 */
export const FIREBASE_AUTHUI_CSS =
    "https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css";

export default function showAuthUi(
    authUi: firebaseui.auth.AuthUI,
    signInFailure?: SignInFailureCallback,
    signInSuccessWithAuthResult?: SignInSuccessCallback
) {
    // - Element with id 'firebase-ui' must be already exists in doccument
    // - Link css (version should be matched with package dependencies)
    //  https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css

    authUi.start("#firebase-ui", {
        signInFlow: "popup",
        signInOptions: [
            // List provider's id.
            // Setup in firebase console -> Authentication
            //  -> signin method -> Sign-in providers
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInFailure,
            signInSuccessWithAuthResult,
        },
    });
}
