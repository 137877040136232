import { useEffect, useRef } from "react";
import { Tooltip } from 'bootstrap'
import { ComponentOptions } from "bootstrap/js/dist/base-component";

/**
 * @type should be Tooltip.Options
 */
const TOOLTIP_OPTION: ComponentOptions = {
    placement: 'auto',
    delay: { show: 500, hide: 0 }
}

export type NoteTitleProps = {
    title: string
}

export default function NoteTitle({ title }: NoteTitleProps) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            Tooltip.getInstance(ref.current)?.dispose();
            Tooltip.getOrCreateInstance(ref.current, TOOLTIP_OPTION)
        }
    }, [title])

    return (
        <div ref={ref} className="content" title={title}>{title}</div>
    )
}