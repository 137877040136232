import { useState } from "react";

export enum UpdateTarget {
    Nothing = "nothing",
    CheckBox = "checkbox",
}

export default function useTargetingUpdate() {
    const [target, setTarget] = useState(UpdateTarget.Nothing);

    return {
        target,
        updateComplete: () => setTarget(UpdateTarget.Nothing),
        updateCheckBox: () => setTarget(UpdateTarget.CheckBox),
    };
}
