import './style.css'

import { createPortal } from "react-dom";
import { AccountTab, ConfigOg } from './Tab'
import { Offcanvas } from 'bootstrap'
import { useCallback, useEffect, useRef, useState } from 'react';
import VanillaSwipe, { EventData } from 'vanilla-swipe';

export default function Sidebar() {
    const sidebarRef = useRef<HTMLDivElement>(null);

    const onSwiped = useCallback((data: EventData) => {
        if (sidebarRef.current == null) return;

        const offcanvs = Offcanvas.getOrCreateInstance(sidebarRef.current);
        if (data.directionX === 'LEFT')
            offcanvs.show();
        if (data.directionX === 'RIGHT')
            offcanvs.hide();

    }, [sidebarRef.current])

    const [swiper] = useState<VanillaSwipe>(new VanillaSwipe({
        delta: 100,
        mouseTrackingEnabled: true,
        element: document as unknown as HTMLElement,
        onSwiped: (_, data) => {
            if (data.absY < 30)
                onSwiped(data)
        }
    }));

    useEffect(() => {
        swiper.init();
    }, [])

    return createPortal(
        <div ref={sidebarRef} className="offcanvas sidebar offcanvas-end" tabIndex={-1}
            id="sidebar-offcanvas" aria-labelledby="sidebar-title">
            <div className="offcanvas-header">
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="nav nav-tabs reverse" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="account-tab" data-bs-toggle="tab" data-bs-target="#account" type="button" role="tab" aria-controls="account" aria-selected="true">Account</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="config-og-tab" data-bs-toggle="tab" data-bs-target="#config-og" type="button" role="tab" aria-controls="config" aria-selected="false">Open graph</button>
                    </li>
                </ul>
                <div className="tab-content p-3 border rounded-bottom">
                    <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                        <AccountTab />
                    </div>
                    <div className="tab-pane fade" id="config-og" role="tabpanel" aria-labelledby="config-og-tab">
                        <ConfigOg />
                    </div>
                </div>
            </div>
        </div >,
        document.getElementById('root') as Element
    )
}