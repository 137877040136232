import OgMeta from "../../Util/OpenGraph/OgMeta";
import { GetHostName } from "../../Util/UrlUtil";
import LoadingBody from "./LoadingBody";

export interface PreviewCardProps {
    data?: CardData | null | undefined
}

export class CardData {
    Url: string;
    Image?: string;
    Title?: string | undefined;
    Description?: string | undefined;

    constructor(url: string, img: string, title?: string, desc?: string) {
        this.Url = url;
        this.Image = img;
        this.Title = title;
        this.Description = desc;
    }

    static FromOgMeta(og: OgMeta): CardData {
        return new CardData(og.Url, og.Image, og.Title, og.Description);
    }
}

export default function PreviewCard({ data }: PreviewCardProps) {
    if (data === undefined) {
        return (
            <div className="card note-group">
                <LoadingBody />
            </div>
        )
    }

    if (data === null || data.Url === null)
        return null;

    return (
        <div className="card note-group">
            <div style={{ backgroundImage: `url('${data.Image}')` }} className="card-img-top"></div>
            <div className="card-body position-relative">
                <a className="link" target="blank" href={data.Url} />
                {data.Title && <span className="title">{data.Title}</span>}
                {data.Description && <div className="description">{data.Description}</div>}
                <span className="site-name">{GetHostName(data.Url)}</span>
            </div>
        </div>
    );
}