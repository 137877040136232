import 'react-toastify/dist/ReactToastify.min.css';

import { useContext, createContext } from "react";
import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';

// #region NotificationContext

export interface INotificationContext {
}

export const NotificationContext = createContext<INotificationContext>({} as INotificationContext);

export function useNotificationContext(): INotificationContext {
    const context = useContext(NotificationContext);
    if (context == null)
        throw new Error('Must be used within NotificationProvider')

    return context;
}

// #endregion NotificationContext

// #region DispatchContext

export interface INotificationDispatch {
}

const NotificationDispatch = createContext<INotificationDispatch>({} as INotificationDispatch);

export function useNotificationDispatch(): INotificationDispatch {
    const context = useContext(NotificationDispatch);
    if (context == null)
        throw new Error('Must be used within NotificationProvider')

    return context;
}

// #endregion DispatchContext

export interface NotificationProviderProps {
    children: any,
}

export default function NotificationProvider({ children }: NotificationProviderProps) {
    const context: INotificationContext = {
    }

    const dispatcher: INotificationDispatch = {
    }

    return (<>
        <NotificationContext.Provider value={context}>
            <NotificationDispatch.Provider value={dispatcher}>
                {children}
            </NotificationDispatch.Provider>
        </NotificationContext.Provider>
        {
            createPortal(
                <ToastContainer position='bottom-left' theme='dark'
                    autoClose={1500} hideProgressBar={true}
                    pauseOnFocusLoss={false}
                    limit={5} closeButton={false} />,
                document.getElementById('root') as Element
            )
        }
    </>)
}