import { createContext, useContext, useState } from "react";
import { createPortal } from "react-dom";
import NoteData, { UpdatableNote } from "../../Data/NoteData";
import Menu, { ClickEventArg } from "../Menu/Menu";
import MenuAction from "../Menu/MenuAction";
import { useDataDispatch } from "./DataContext";
import { useModalDispatch } from "./ModalContext";
import { DeleteNote, UpdateNote } from "../../Firebase";
import { toast } from "react-toastify";
import UndoToast from "../Common/UndoToast";

// #region MenuContext

interface IMenuContext {
}

const MenuContext = createContext<IMenuContext>({} as IMenuContext);

export function useMenuContext(): IMenuContext {
    const context = useContext(MenuContext);
    if (context == null)
        throw new Error('Must be used within MenuProvider')

    return context;
}

// #endregion MenuContext

// #region DispatchContext

interface IMenuDispatch {
    showMenu: (ref: HTMLElement, note: NoteData) => void;
}

const DispatchContext = createContext<IMenuDispatch>({} as IMenuDispatch);

export function useMenuDispatch(): IMenuDispatch {
    const context = useContext(DispatchContext);
    if (context == null)
        throw new Error('Must be used within MenuProvider')

    return context;
}

// #endregion DispatchContext

export interface MenuProviderProps {
    children?: any;
}

export default function MenuProvider({ children }: MenuProviderProps) {

    const { showModal } = useModalDispatch();
    const { localUpdate, localDelete } = useDataDispatch();

    const [show, setShow] = useState(false);
    const [noteData, setData] = useState<NoteData | null>(null);
    const [refElem, setRefElem] = useState<HTMLElement | null>(null);

    function onMenuClick(arg: ClickEventArg) {
        if (arg.action !== MenuAction.Unknown)
            setShow(false);

        switch (arg.action) {
            case MenuAction.Edit:
                showModal(noteData);
                break;

            case MenuAction.Update:
                if (arg.data && noteData) {
                    const color = UpdatableNote.Color(arg.data);
                    UpdateNote(noteData.Id, color)
                        .then(() => localUpdate(noteData.Id, color))
                        .catch(console.error)
                }
                break;

            case MenuAction.Delete:
                if (noteData) {
                    let skipRemove = false;
                    const toastId = toast(
                        <>
                            <UndoToast
                                content={<div>Delete item <b className="text-info">{noteData.Title}</b></div>}
                                undo={() => {
                                    skipRemove = true;
                                    toast.dismiss(toastId);
                                }} />
                        </>,
                        {
                            autoClose: 3000,
                            closeOnClick: false,
                            hideProgressBar: false,
                            pauseOnFocusLoss: true,
                            onClose: () => {
                                if (skipRemove) return;
                                toast.promise(DeleteNote(noteData.Id), {
                                    pending: 'Deleting ...',
                                    success: {
                                        render: ({ data }) => {
                                            localDelete(noteData.Id);
                                            return <span>Deleted item <b>{data?.Title}</b></span>
                                        }
                                    },
                                    error: {
                                        render: (prop) => {
                                            console.error(prop);
                                            return 'Unknown error';
                                        }
                                    }
                                })
                            }
                        })
                }
                break;
        }
    }

    const context: IMenuContext = {
    }

    const dispatcher: IMenuDispatch = {
        showMenu: (ref: HTMLElement, note: NoteData) => {
            setRefElem(ref);
            setData(note);
            setShow(true);
        }
    }

    function reset() {
        setRefElem(null);
        setData(null);
        setShow(false);
    }

    return (
        <>
            <MenuContext.Provider value={context}>
                <DispatchContext.Provider value={dispatcher}>
                    {children}
                </DispatchContext.Provider>
            </MenuContext.Provider>
            {
                createPortal(
                    <>
                        <Menu show={show} refElem={refElem} note={noteData}
                            onHidden={reset} onClick={onMenuClick} />
                        <div onClick={() => setShow(false)} className="menu-backdrop"></div>
                    </>,
                    document.getElementById('root') as Element
                )
            }
        </>
    )
}