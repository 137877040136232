export interface HeaderSorterItemProps {
    active: boolean
    name: string | undefined
    value: string
    className: string
}

export default function HeaderSorterItem(props: HeaderSorterItemProps) {
    const { active, className, name, value } = props;
    const itemClass = name ? 'dropdown-item' : className;

    return (
        <div data-value={value} className={itemClass + (active ? ' active' : '')}>
            <span className={'sort-icon ' + className}></span>
            {name && <span className="ml-2">{name}</span>}
        </div>
    )
}