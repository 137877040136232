import './style.css'

import { ViewMode } from "../../Definition";
import HeaderFilter from './HeaderFilter';
import HeaderSorter from './HeaderSorter';
import { useAppContext, useAppDispatch } from '../Context/AppContext';
import { useModalDispatch } from '../Context/ModalContext';
import { useDataDispatch } from '../Context/DataContext';
import SearchBar from './SearchBar';

export interface HeaderProps { }

export default function Header(props: HeaderProps): JSX.Element {
    const { viewMode } = useAppContext();
    const { showModal } = useModalDispatch();
    const { setViewMode } = useAppDispatch();
    const { refresh } = useDataDispatch();

    function toggleViewMode() {
        if (viewMode === ViewMode.Grid)
            setViewMode(ViewMode.List);
        else
            setViewMode(ViewMode.Grid);
    }

    const toggleClass = viewMode === ViewMode.Grid ? 'grid-view' : 'list-view';
    return (
        <div className="row justify-content-center pt-3 header">
            <div className="col-12 col-sm col-md-6 mb-2">
                <SearchBar />
            </div>
            <div className="col-12 col-sm-auto mb-2">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <button onClick={() => showModal(null)} className="btn border-secondary btn-add-note" data-role="create-note"></button>
                        <button onClick={refresh} className="btn border-secondary btn-refresh" data-role="refresh"></button>
                        <button onClick={toggleViewMode} data-role="toggle-view"
                            className={'btn border-secondary btn-view-mode ' + toggleClass} />
                    </div>
                    <HeaderFilter />
                    <HeaderSorter />
                </div>
            </div>
        </div>
    );
}