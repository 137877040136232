import './firebase-auth.css'

import { useEffect } from "react";
import { FIREBASE_AUTHUI_CSS } from "../../Firebase/AuthUi";
import { useExternalCss } from "../../hook/useScript";
import { useUserContext, useUserDispatch } from '../Context/UserContext'

/**
 * Firebase auth ui
 */
export default function FirebaseAuthUi() {
    const { showAuthUi } = useUserDispatch();
    const { loading: userLoading, user } = useUserContext();
    const { loading: cssLoading } = useExternalCss(FIREBASE_AUTHUI_CSS);

    useEffect(() => {
        if (userLoading || cssLoading) return;
        if (user == null)
            showAuthUi(() => false/* self handle */)
    }, [userLoading, cssLoading, user]);

    return null;
}