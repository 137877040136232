import OgMeta from "./OgMeta";

/**
 * @see createEndpoint()
 * @see useConfigurableOpenGraph()
 */
export default function fetchOpenGraph(url: string): Promise<OgMeta> {
    if (typeof url !== "string" || url.length < 3) return Promise.reject(url);

    return fetch(url)
        .then((res) => res.json())
        .then((meta: any) => {
            if (meta.url && meta.image && meta.image.url) {
                return new OgMeta(
                    meta.url,
                    meta.image.url,
                    meta.title,
                    meta.description
                );
            }

            return Promise.reject(meta);
        });
}
