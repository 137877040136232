
export interface SpinnerProps {
    /**
     * center horizontal
     */
    hCenter?: boolean,
    /**
     * center vertical
     */
    vCenter?: boolean,
    className?: string
}

export default function Spinner(props: SpinnerProps) {
    const { hCenter = false, vCenter = false, className = "" } = props;

    let centerClass = hCenter ? 'd-flex justify-content-center' : '';
    if (vCenter)
        centerClass += ' align-items-center';

    return (
        <div className={[className, centerClass].join(' ')}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}