import { GenerateKeyWords, RandomString } from "../Util/StringUtil";
import { GetHostName } from "../Util/UrlUtil";

export class ValidateResult {
    States: string[];

    get IsSuccess() {
        return this.States == null || this.States.length === 0;
    }

    constructor(...state: string[]) {
        this.States = [...state];
    }
}

export class UpdatableNote {
    Id?: string | undefined;
    Title?: string | undefined;
    Seen?: boolean | undefined;
    Color?: string | undefined;
    Link?: string | undefined;
    Content?: string[] | undefined;
    KeyWords?: string[] | undefined;
    UpdateTime?: number;
    /**
     * @returns Empty array -> success
     */
    Validate(): ValidateResult {
        const output = new ValidateResult();
        if (this.Title == null || this.Title.length === 0)
            output.States.push("Title");

        return output;
    }

    static Seen(seen?: boolean) {
        const output = new UpdatableNote();
        output.UpdateTime = new Date().getTime();
        seen != null && (output.Seen = seen);
        return output;
    }
    static Color(color?: string) {
        const output = new UpdatableNote();
        output.UpdateTime = new Date().getTime();
        color != null && (output.Color = color);
        return output;
    }

    static From(note: NoteData | undefined) {
        const output = new UpdatableNote();
        if (note == null) return output;

        output.Color = note.Color;
        output.Content = note.Content;
        output.Id = note.Id;
        output.KeyWords = note.KeyWords;
        output.Link = note.Link;
        output.Seen = note.Seen;
        output.Title = note.Title;
        output.UpdateTime = note.UpdateTime;
        return output;
    }

    // if props of this class was changed,
    // the DataCollection.Update() function must be updated
}

export default class NoteData {
    Id: string;
    Title: string;
    Seen: boolean;
    CreateTime: number;
    UpdateTime: number;
    Color: string;
    Link: string;
    Content: string[];
    KeyWords: string[];

    get DisplayLink() {
        const host = GetHostName(this.Link);
        return host.length === 0 ? this.Link : host;
    }

    get IsEmpty() {
        return this.Id.length === 0 || this.Title.length === 0;
    }

    get ShouldExpand() {
        const hasLink = this.Link != null && this.Link.length > 0;
        const hasContent = this.Content != null && this.Content.length > 0;
        return hasContent || hasLink;
    }

    constructor() {
        this.Id = "";
        this.Title = "";
        this.Seen = false;
        this.CreateTime = 0;
        this.UpdateTime = 0;
        this.Link = "";
        this.Color = "default";
        this.Content = [];
        this.KeyWords = [];
    }

    GenerateId() {
        this.Id = RandomString(6);
    }

    GenerateKeyword() {
        this.KeyWords = GenerateKeyWords(this.Title, this.Content);
    }

    static FromJson(json: any): NoteData {
        return Object.assign(new NoteData(), json);
    }

    static FromProps(props: UpdatableNote): NoteData | null {
        if (props == null || props.Validate().IsSuccess == false) return null;

        const output = new NoteData();
        props.Title && (output.Title = props.Title);
        if (Array.isArray(props.Content)) {
            output.Content = props.Content.filter(
                (c) => c != null && c.length > 0
            );
        }
        props.Link && (output.Link = props.Link);
        props.UpdateTime && (output.UpdateTime = props.UpdateTime);

        return output;
    }

    static get Empty(): NoteData {
        return new NoteData();
    }
}
