import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useAppContext } from './AppContext';
import DataCollection from '../../Data/DataCollection';
import NoteData, { UpdatableNote } from '../../Data/NoteData';
import useAsync from '../../hook/useAsync';
import { GetNotes } from '../../Firebase';
import { Filter } from '../../Definition';

// #region DataContext

interface IDataContext {
    FilteredValue: NoteData[],
    loading: boolean,
    searchTerm: string | undefined | null
    GetAllValues: () => NoteData[],
}

const DataContext = createContext<IDataContext>({} as IDataContext);

export function useDataContext(): IDataContext {
    const context = useContext(DataContext);
    if (context == null)
        throw new Error('Must be used within DataProvider')

    return context;
}

// #endregion DataContext

// #region DispatchContext

/**
 * Data context dispatcher
 */
interface IDataDispatch {
    refresh: () => void,
    search: (keyword: string) => void,
    backSearch: () => string | undefined,
    localCreate: (data: NoteData) => Promise<NoteData>,
    localUpdate: (id: string, updates: UpdatableNote, shouldRerender?: boolean) => Promise<NoteData | undefined>;
    localDelete: (id: string) => Promise<NoteData | undefined>;
}

const DispatchContext = createContext<IDataDispatch>({} as IDataDispatch);

export function useDataDispatch(): IDataDispatch {
    const context = useContext(DispatchContext);
    if (context == null)
        throw new Error('Must be used within DataProvider')

    return context;
}

// #endregion DispatchContext

export interface DataProviderProps {
    children: any,
}

export default function DataProvider(props: DataProviderProps) {
    const { children } = props;
    const { filter, sorter } = useAppContext();

    const keywordRef = useRef<string[]>([]);
    const [_, rerender] = useReducer(c => c + 1, 0);
    const { loading, value } = useAsync(GetNotes.bind(GetNotes, filter == Filter.All ? undefined : filter == Filter.Seen));
    const [list, setList] = useState<DataCollection>(new DataCollection());

    useEffect(() => {
        if (Array.isArray(value))
            setList(new DataCollection(value));
    }, [loading]);

    const searchTerm = keywordRef.current[keywordRef.current.length - 1];
    const context: IDataContext = {
        loading, searchTerm,
        GetAllValues: () => list.AllItems,
        FilteredValue: list.Search(searchTerm).Sort(sorter).Filter(filter).FilteredItems
    }

    const dispatcher: IDataDispatch = {
        refresh: () => rerender(),
        search: (keyword: string) => {
            const index = keywordRef.current.indexOf(keyword);
            if (index > -1)
                keywordRef.current.splice(index, 1);

            keywordRef.current.push(keyword);
            rerender();
        },
        backSearch: () => {
            const kw = keywordRef.current.pop();
            rerender();
            return kw;
        },
        localCreate: async (note: NoteData) => {
            if (note == null) throw new Error('Expected an instance of NoteData');

            note = list.Add(note)
            rerender();

            return note;
        },
        localUpdate: (id: string, updates: UpdatableNote, shouldRerender = true) => {
            shouldRerender && rerender();
            return Promise.resolve(list.Update(id, updates));
        },
        localDelete: async (id: string) => {
            const n = await Promise.resolve(list.Remove(id));
            rerender();
            return n;
        },
    }

    return (
        <DataContext.Provider value={context}>
            <DispatchContext.Provider value={dispatcher}>
                {children}
            </DispatchContext.Provider>
        </DataContext.Provider>
    )
}
