import React from "react";

export interface FooterProps {
    onOk: (modal: HTMLElement) => void,
}

export default function Footer({ onOk }: FooterProps) {

    function onClick(e: React.MouseEvent) {
        const target = e.target as HTMLElement;
        const modal = target.closest('.modal');
        if (typeof onOk === 'function')
            onOk.call(onOk, modal as HTMLElement);
    }

    return (<>
        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
        <button onClick={onClick} type="button" className="btn btn-outline-primary">Save changes</button>
    </>)
}