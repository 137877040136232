const CHARS = "qwertyuiopasdfghjklzxcvbnm123456789";

export function RandomString(length: number) {
    const output = [];
    const randomNum = () => (Math.random() * 100) % CHARS.length;

    for (let i = 0; i < length; i++) {
        output.push(CHARS.charAt(randomNum()));
    }
    return output.join("");
}

export function RemoveUnicode(string = ""): string {
    string = string.toLowerCase();
    string = string.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    string = string.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    string = string.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    string = string.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    string = string.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    string = string.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    string = string.replace(/đ/g, "d");
    return string;
}

export function GenerateKeyWords(title?: string, content?: string[]) {
    title = title + "";
    content = content || [];

    const cleanAndSplitString = (string = "") => {
        string = string.toLowerCase();
        string = string.replace(/\s{2,}/g, " ");
        string = RemoveUnicode(string);

        return string.split(" ").filter((s) => s.length > 0);
    };

    const distinct = new Set([
        ...cleanAndSplitString(title),
        ...content.map(cleanAndSplitString).flat(),
    ]);

    return Array.from(distinct);
}
