import { Filter } from '../../Definition';
import { useAppContext, useAppDispatch } from '../Context/AppContext';

const FILTERS = [
    { Name: "All", Value: Filter.All },
    { Name: "New", Value: Filter.New },
    { Name: "Seen", Value: Filter.Seen },
];

export interface FilterProps { }

export default function HeaderFilter(props: FilterProps) {
    const { filter } = useAppContext();
    const { setFilter } = useAppDispatch();

    return (
        <select value={filter} onChange={e => setFilter(e.target.value as Filter)}
            className="custom-select" data-role="note-filter">
            {FILTERS.map(f => <option key={f.Value} value={f.Value}>{f.Name}</option>)}
        </select>
    )
}