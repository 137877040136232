import './style.css'

import { useEffect, useRef } from "react";
import { Modal } from 'bootstrap'
export interface ModalProps {
    /**
     * specify 'static' for a backdrop 
     * which doesn't close the modal on click.
     */
    backdrop?: boolean | 'static',
    /**
     * Closes the modal when escape key is pressed
     */
    keyboard?: boolean,
    show: boolean,
    onHidden?: () => void,
}

export interface ModalSection extends ModalProps {
    header?: any,
    body?: any,
    footer?: any,
}

export default function BaseModal(props: ModalSection) {
    const {
        header, body, footer,
        show = false, keyboard = true, backdrop = 'static',
        onHidden
    } = props;

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ref.current == null)
            return;

        ref.current.addEventListener('show.bs.modal',
            e => { });
        ref.current.addEventListener('shown.bs.modal',
            e => { });
        ref.current.addEventListener('hide.bs.modal',
            e => { });
        ref.current.addEventListener('hidden.bs.modal',
            () => {
                if (typeof onHidden === 'function')
                    onHidden.call(onHidden);
            });
    }, [])

    useEffect(() => {
        if (ref.current == null)
            return;

        const modal = Modal.getOrCreateInstance(ref.current);
        if (show)
            modal.show();
        else
            modal.hide();

    }, [show, ref])

    return (
        <div ref={ref} id="input-modal" className="modal fade" tabIndex={-1}
            data-bs-backdrop={backdrop}
            data-bs-keyboard={keyboard}>
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        {header}
                    </div>
                    <div className="modal-body custom-scrollbar">
                        {body}
                    </div>
                    <div className="modal-footer">
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    )
}